import React from 'react';
import styled from 'styled-components';
import PriceFilter from './filter/Price';
import RatingFilter from './filter/Rating';
import TypeFilter from './filter/Type';
import AreaFilter from './filter/Area';
import ViewToggle from './ViewToggle';

export default class Sidebar extends React.Component {
  
  render() {

    return (
    	<Wrapper>

      <Intro>
        <h1>Welcome to Best in Brighton, the best places in Brighton collated by locals</h1>
        <p>Use the map or list view to find recommendated places, click on a place to view more information about a specific recommendation.</p>
      </Intro>

       <h2>Search places</h2> <br/>
       <p className="count">{this.props.count} places found</p>

      <ViewToggle />

      <PriceFilter />
      <TypeFilter types={this.props.types} />
      <AreaFilter areas={this.props.areas} />
      <RatingFilter />

      <div className="resetButton">
        <button id="resetBtn" onClick={this.props.reset}>Reset filters</button>
      </div>  

      </Wrapper>
    
    )
  }
}

const Wrapper = styled.div`
  padding: 15px;

  h2 {
    margin-bottom: 0;
  }

  .count {
    margin-top: 0;
  }

  .label {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 15px;
  }

  .resetButton {
    margin-top: 20px;

    button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      line-height: 1em;
      appearance: none;
      background: transparent;
      border: 2px solid orange;
      outline: none;
      border-radius: 5px;
      color: orange;
      transition: all 0.5s;

      &:hover {
        cursor: pointer;
        background: orange;
        color: #fff;
      }
    }
  }

  input[type='radio'] {
    appearance: none;
    border: 1px solid grey;
    width: 12px;
    height: 12px;
    border-radius: 50px;

    &:checked {
      background: orange;
      outline: none;

      &:after {
        background: #fff;
      }
    }
  }

  select#rating {
    font-size: 16px;
    margin-top: 8px;
    padding: 5px;
    border: 2px solid orange;
    border-radius: 5px;
    width: 100%;
  }


  label {
    border: 2px solid orange;
    padding: 5px 8px 5px 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: inline-flex;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }
  }

  .rating-label,
  .price-label {
    margin-bottom: 5px;
  }
`

const Intro = styled.div`
  h1 {
    margin-top: 0;
  }
`