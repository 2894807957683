import React from 'react';
import styled from 'styled-components';

const Star = ({ marked, starId, update }) => {
    return (
      <Wrapper 
        data-star-id={starId} 
        className="star" 
        role="button" 
        onClick={e => update(e.target.getAttribute('data-star-id')) }>
        {marked ? '\u2605' : '\u2606'}
      </Wrapper>
    );
  };

  export default Star;

  const Wrapper = styled.span`
    font-size: 30px;
    padding: 5px;
    margin-top: 10px;
    color: #ff9900;

    &:hover {
        cursor: pointer;
    }
  `