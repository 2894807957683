import React, { useContext, useEffect } from "react";
import styled from 'styled-components';
import { GlobalContext } from 'context/GlobalProvider'

function AreaFilter(props) {
  const { areaFilter, setAreaFilter, reset, setReset } = useContext(GlobalContext);
  const areas = props.areas;

  useEffect(() => {
    if (reset === true) {
        setAreaFilter('all');
        // Reset area select
        const areaSelect = document.querySelector('option.area-any');
        areaSelect.setAttribute('selected', true);
        areaSelect.removeAttribute('selected', false);
        
        setReset(false);
    }
  });

  function updateArea(e) {
       setAreaFilter(e.target.value);
  }

  return(
      <div>
      <p className="label">Area</p>

      <Select name="area" id="area"  onChange={(e) => { updateArea(e) }} onBlur={(e) => { updateArea(e) }}>
        <option value="all" className="area-any">All</option>

        {areas.map((area) => {
             return (
                <option key={area} value={area}>{area}</option>
             );
        }) }
      </Select>


    
  </div>
  );
}

export default AreaFilter;

const Select = styled.select`
    font-size: 16px;
    margin-top: 8px;
    padding: 5px;
    border: 2px solid orange;
    border-radius: 5px;
    width: 100%;
`