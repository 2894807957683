import React, { useContext, useEffect } from "react";
import StarRating from '../StarRating';
import { GlobalContext } from 'context/GlobalProvider'

function RatingFilter() {
    const { ratingFilter, setRatingFilter, reset, setReset } = useContext(GlobalContext);

    useEffect(() => {
        if (reset === true) {
          setRatingFilter('all');
          setReset(false);
        }
    });

    function updateRating(e) {
   
        let ratingValue = 'all';
        if (e === '1') {
            ratingValue = 'one';
        }

        if (e === '2') {
            ratingValue = 'two';
        }

        if (e === '3') {
            ratingValue = 'three'; 
        }

        if (e === '4') {
            ratingValue = 'four';
        }

        if (e === '5') {
            ratingValue = 'five';
        }

        setRatingFilter(ratingValue);
    }


    return(
        <div>
             <p className="label">Star rating</p>
            <StarRating value={0} updateRating={updateRating} />
        </div>
    );
}

export default RatingFilter;