import React, { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from 'context/GlobalProvider'
import { FaMapPin, FaList } from 'react-icons/fa';


function ViewToggle() {
    const { mapView, setMapView } = useContext(GlobalContext);

    function resetClasses() {
        const viewBtns = document.querySelectorAll('.view-toggle-btn-group button');
        viewBtns.forEach(btn => {
            btn.classList.remove('active');
        }); 
    }
    
    function changeView(e) {
        if (e.target.value === 'List') {
            setMapView(false);
            resetClasses();
            e.target.classList.toggle('active');
        } 
        if (e.target.value === 'Map') {
            setMapView(true);
            resetClasses();
            e.target.classList.toggle('active');
        }
    }
  
    return(
        <BtnGroup className="view-toggle-btn-group">
            <button value="Map"  className="active" onClick={changeView}><FaMapPin /> Map</button>
            <button value="List" onClick={changeView}><FaList /> List</button>
        </BtnGroup>
    );
  }
  
  export default ViewToggle;

  const BtnGroup = styled.div`
  margin-bottom: 10px;
  overflow: hidden;

  button {
    font-size: 15px;
    padding: 15px 5px;
    width: 25%;
    background-color: #ff9900;
    border: 1px solid orange; 
    color: #fff; 
    cursor: pointer;
    float: left;
    transition: all 0.5s;

    @media(min-width: 1025px) {
      padding: 10px 20px; 
    }

    &:hover,
    &.active {
      background-color: transparent;
      color: orange;
    }
  }
`