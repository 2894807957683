import React from "react";
import styled from 'styled-components';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

export default class PremiumSocial extends React.Component {

  render() {
  	const { recommendation } = this.props;     

    if (recommendation && recommendation.premium) {
        return (
        <>
          {recommendation.facebook !== null && <SocialLinks href={recommendation.facebook} target="_blank"><FaFacebook /></SocialLinks>}
          {recommendation.twitter !== null && <SocialLinks href={recommendation.twitter} target="_blank"><FaTwitter /></SocialLinks>}
          {recommendation.instagram !== null && <SocialLinks href={recommendation.instagram} target="_blank"><FaInstagram /></SocialLinks>}
        </>
        );
     } 

    return('');
  }
}

const SocialLinks = styled.a`
	text-decoration: none;
	background-color: #ff9900;
	color: #fff;
	padding: 10px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #ff9900;
  transition: all 0.5s;
  font-size: 20px;
  line-height: 0.25em;
  vertical-align: bottom;
  margin: 15px 2px 0 2px;
  align-self: start;
  display: flex;

   	&:hover {
   		background: transparent;
   		color: #ff9900;
   		border: 1px solid #ff9900;
   		cursor: pointer;
   	}
`