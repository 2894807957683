import React from "react";
import styled from 'styled-components';
import { Marker, Popup } from "react-leaflet";
import Rating from 'components/Rating';


function MapMarker(props) {

    const recommendation = props.recommendation;
    const modalOpen = props.modalOpen;

        return (
        <Marker
            onClick={(e) => { 
                modalOpen(recommendation.node);
            }}
            key={recommendation.node.id}
            data={recommendation.node.type.name}
            position={[recommendation.node.location.lat, recommendation.node.location.lon]}
            onMouseOver={(e) => {
            e.target.openPopup();
            }}
            onMouseOut={(e) => {
            e.target.closePopup();
            }}
        >
            <StyledPopup>
            <div className="card">
                <div className="card-body">
                <h3 className="card-title">{recommendation.node.name}</h3>
                <Rating stars={recommendation.node.rating} />
                </div>
            </div>
            </StyledPopup>
        </Marker>
        )
    }


export default MapMarker;


const StyledPopup = styled(Popup)`
  text-align: center;

  h3 {
    margin-bottom: 5px;
  }

  .rating {
    display: block;
    text-align: center;
  }
`