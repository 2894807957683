import React, { useContext, useEffect } from "react";
import { GlobalContext } from 'context/GlobalProvider'

function TypeFilter(props) {

    const { typeFilter, setTypeFilter, reset, setReset } = useContext(GlobalContext);
    const types = props.types;

    useEffect(() => {
        if (reset === true) {
           setTypeFilter('all');
           // Reset type radio buttons
           var ele = document.getElementsByName('type');
           for(var i=0;i<ele.length;i++)
           ele[i].checked = false;
          setReset(false);
        }
      });

    function updateType(e) {
        setTypeFilter(e.target.value);
    }

    return(
        <div>
            <p className="label type-label">Type</p>

            {types.map(({ node }) => {
                return (
                    <label htmlFor={node.name.toLowerCase()} key={node.name}>
                    <input type="radio" id={node.name.toLowerCase()} name="type" value={node.name.toLowerCase()} onClick={updateType} />
                    {node.name}</label>
                )
            })}
        </div>
    );
}

export default TypeFilter;
