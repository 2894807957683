import React, {useContext, useEffect} from 'react';
import Star from './Star';
import { GlobalContext } from 'context/GlobalProvider';
import styled from 'styled-components';

const StarRating = ({ value, updateRating }) => {
    const [rating, setRating] = React.useState(parseInt(value) || 0);
    const [selection, setSelection] = React.useState(0);
    const { reset, setReset } = useContext(GlobalContext);  

    // If reset global state is true (i.e reset button clicked) then reset selection
    useEffect(() => {
      if (reset === true) {
        setSelection(0);
        setRating(0);
        setReset(false);
      }
    });
  
    const hoverOver = event => {
      let val = 0;
      if (event && event.target && event.target.getAttribute('data-star-id'))
        val = event.target.getAttribute('data-star-id');
        setSelection(val);
      };

    return (
      <Wrapper
        onMouseOut={() => hoverOver(null)}
        onClick={e => setRating(e.target.getAttribute('data-star-id') || rating) }
        onMouseOver={hoverOver}
      >
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1}`}
            marked={selection ? selection >= i + 1 : rating >= i + 1}
            update={updateRating}
          />
        ))}
      </Wrapper>
    );
  };


  export default StarRating;
  
  const Wrapper = styled.div`
    margin-top: 10px;
  `