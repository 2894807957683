import React, { Component, useEffect } from "react";
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import L from 'leaflet';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import Layout from 'components/Layout';
import DefaultSidebar from 'components/Sidebar';
import Modal from 'components/Modal';
import RecommendationList from 'components/RecommendationList';
import MarkerList from 'components/map/MarkerList';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Rating from 'components/Rating';
import { isDomAvailable } from 'lib/util';
import { GlobalContext } from 'context/GlobalProvider'

ReactModal.setAppElement('#___gatsby');

class InfoMap extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = { 
      isNoResultsModalOpen: false,
      isModalOpen: false,
      location:  {
        lat: 50.8374127,
        lng: -0.141209,
      },
      defaultZoom: 12
    };

    this.reset = this.reset.bind(this);
  }

  handleModalOpen = (e) => {
    this.setState({ 
      isModalOpen: true,
      selectedRecommendation: e
    })
  }

  handleModalClose = (e) => {
    this.setState({ 
      isModalOpen: false 
    })
  }

  reset = (e) => {
    // Set map state values back to defaults
    this.setState({
      location:  {
        lat: 50.8374127,
        lng: -0.141209,
      },
      defaultZoom: 12
    });

    // Set global reset to true to reset filters.
    this.context.setReset(true);
  }

  updateLocation = (loc) => {
    this.setState({ 
      location: {
        lat: loc.lat,
        lng: loc.lon,
      },
      defaultZoom: 20
    }); 
  } 

render() {

 const {
   priceFilter, 
   ratingFilter,
   typeFilter,
   areaFilter,
   mapView,
   markerCount
  } = this.context;

 const LOCATION = {
    lat: this.state.location.lat,
    lng: this.state.location.lng,
  };

  const mapSettings = {
    center: [LOCATION.lat, LOCATION.lng],
    defaultBaseMap: "OpenStreetMap",
    zoom: this.state.defaultZoom
  }

  const types = get(this, 'props.data.type.edges');
  const recommendations = get(this, 'props.data.all.edges');
  const areas = get(this, 'props.data.area.distinct');

    

  //  { MarkerList().length === 0 && alert('Unfortunately there are no results, please try again.') }


  //console.log(MarkerList.);

    return ( 
      <>
        <Layout>
          <Helmet>
            <title>Best places in Brighton recommended by locals</title>
          </Helmet>
          <Row>
            <Sidebar>
              <DefaultSidebar 
                  count={markerCount} 
                  reset={this.reset} 
                  types={types} 
                  areas={areas}
                />
            </Sidebar>

            { mapView  &&
            <MainMap {...mapSettings} scrollWheelZoom={false} touchZoom={false} doubleClickZoom={true}> 
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <MarkerList recommendations={recommendations} modalOpen={this.handleModalOpen}/>

            </MainMap>
            } 


              { !mapView  &&
                <RecommendationList nodes={recommendations} />
              }


          </Row>
        </Layout>

        <Modal isOpen={this.state.isModalOpen} onRequestClose={this.handleModalClose} selectedRecommendation={this.state.selectedRecommendation}/>
      </>
    );
  }
}

export default InfoMap


export const pageQuery = graphql`
  query MapIndexQuery {
    all: allContentfulRecommendation(sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          website
          priceRange
          slug
          premium
          area
          address {
            address 
          }
          type {
            name
          }
          images {
            fixed(width: 400, height: 300) {
              ...GatsbyContentfulFixed
            } 
          }
          location {
            lat
            lon
          }
          rating
          description {
            description
          }
        }
      }
    }

    type: allContentfulIndustry {
      edges {
        node {
          name
        }
      }
    }

    area: allContentfulRecommendation {
      distinct(field: area)
    }
  
  }
`;

const MainMap = styled(Map)`
 height: 100vh;
 width: 100%;

 .bb-control {
    button {
      width: auto;
      margin-right: 5px;
      line-height: 1em;
    }
 }
`

// const StyledPopup = styled(Popup)`
//   text-align: center;

//   h3 {
//     margin-bottom: 5px;
//   }

//   .rating {
//     display: block;
//     text-align: center;
//   }
// `

const Row = styled.div`
  display: flex;

  @media(max-width: 650px) {
    flex-direction: column; 
  }

  .ReactModal__Overlay {
    z-index: 9991;
  }
`

const Sidebar = styled.div`
  width: 100%;
  padding: 10px;

  @media(min-width: 650px) {
    width: 40%;
  }
`
