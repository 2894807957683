import React, { Component } from "react";
import styled from 'styled-components';
import { GlobalContext } from 'context/GlobalProvider'
import RecommendationTeaser from "../components/recommendation/RecommendationTeaser";


class RecommendationList extends Component {
    static contextType = GlobalContext;

    render(props) {
        const {
            priceFilter, 
            ratingFilter,
            typeFilter,
            areaFilter
            } = this.context;

        const nodes = this.props.nodes;

        return(
            <Wrapper>  
                <ul>

            {nodes
                .filter(recommendation => typeFilter === 'all' ? recommendation : (recommendation.node.type.name.toLowerCase() === typeFilter))
                .filter(recommendation => ratingFilter === 'all' ? recommendation : (recommendation.node.rating.toLowerCase() === ratingFilter))
                .filter(recommendation => priceFilter === 'all' ? recommendation : (recommendation.node.priceRange === priceFilter))
                .filter(recommendation => areaFilter === 'all' ? recommendation : (recommendation.node.area === areaFilter))
                .map(recommendation => {
            return(
                    <li key={recommendation.node.id}>
                        <RecommendationTeaser node={recommendation.node}/>
                    </li>
                );
            })}
                </ul>
            </Wrapper>
        );
    }
    

}


export default RecommendationList;

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    border-left: 1px solid #b9b9b9;

    ul {
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
        
        li {
          padding: 5px;
          flex: 0 0 100%;

          .gatsby-image-wrapper {
            width: 100% !important;
            height: 300px !important;
          }

          @media(min-width: 768px) {
            flex: 0 0 25%;

            .gatsby-image-wrapper {
                width: 275px !important;
                height: 200px !important;
              }
          }
        }
      }

      h2 {
        padding: 10px 0;
        border-top: 1px solid #d8cfcf;
        border-bottom: 1px solid #d8cfcf;
      } 
`