import React, { useContext, useEffect } from "react";
import styled from 'styled-components';
import { GlobalContext } from 'context/GlobalProvider'

function PriceFilter() {
  const { priceFilter, setPriceFilter, reset, setReset } = useContext(GlobalContext);

  useEffect(() => {
    if (reset === true) {
      setPriceFilter('all');
      const priceBtns = document.querySelectorAll('.price-btn-group button');
      priceBtns.forEach(btn => {
        btn.classList.remove('active');
      });  

      setReset(false);
    }
  });

  function updatePrice(e) {
      setPriceFilter(e.target.value);
      //Reset classes
      const priceBtns = document.querySelectorAll('.price-btn-group button');
      priceBtns.forEach(btn => {
          btn.classList.remove('active');
      });

      e.target.classList.toggle('active');
  }

  return(
      <div>
      <p className="label price-label">Price</p>
      <BtnGroup className="price-btn-group">
          <button value="Free" onClick={updatePrice}>Free</button>
          <button value="£" onClick={updatePrice}>£</button>
          <button value="££" onClick={updatePrice}>££</button>
          <button value="£££" onClick={updatePrice}>£££</button>
      </BtnGroup>
  </div>
  );
}

export default PriceFilter;

const BtnGroup = styled.div`
  margin-bottom: 10px;
  overflow: hidden;

  button {
    padding: 15px 5px;
    width: 25%;
    background-color: #ff9900;
    border: 1px solid orange; 
    color: #fff; 
    cursor: pointer;
    float: left;
    transition: all 0.5s;

    @media(min-width: 1025px) {
      padding: 10px 20px; 
    }

    &:hover,
    &.active {
      background-color: transparent;
      color: orange;
    }
  }
`