import React from "react";
import styled from 'styled-components';
import Img from "gatsby-image";
import Truncate from 'react-truncate';
import Rating from 'components/Rating';
import { useStaticQuery, graphql } from "gatsby"


function RecommendationTeaser(props) {

    const fallbackImgData = useStaticQuery(graphql`
        query fallbackQuery {
            file(relativePath: { eq: "teaser-fallback.png" }) {
                childImageSharp {
                    fixed(width: 300, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const place = props.node;
    const bodyText = place.description.description;
    const fallbackImg = fallbackImgData.file.childImageSharp.fixed;
    const teaserImg = place.images ? place.images[0].fixed : fallbackImg;

    return(
        <StyledLink href={`/recommendations/${place.slug}`}>
            <StyledImg fixed={teaserImg}/> 
            <StyledH3>{place.name}  <Rating stars={place.rating} /></StyledH3>			      
        
            <div className="teaser-text">
                    <Truncate
                    lines={1}
                    width={500}
                    ellipsis='&hellip;'
                    >
                    {bodyText}
                </Truncate>
            </div>
        </StyledLink>

    );
}

export default RecommendationTeaser;


const StyledImg = styled(Img)`
    border-radius: 5px;
`

const StyledH3 = styled.h3`
    margin-top: 8px;
    font-size: 18px;
    font-weight: 400;
    color: black;

    .rating {
        font-size: 12px;
        line-height: 1em;
        float: right;
    }
`


const StyledLink = styled.a`
    text-decoration: none;
    &:hover {
        text-decoration: none !important;
    }

    .teaser-text {
        color: #b9b9b9;
    }
`

const StyledButton = styled.button`
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    line-height: 1em;
    appearance: none;
    background: transparent;
    border: 2px solid orange;
    outline: none;
    border-radius: 5px;
    color: orange;
    transition: all 0.5s;
    display: block;

    &:hover {
        cursor: pointer;
        background: orange;
        color: #fff;
    }
`

const TagWrapper = styled.div`
    margin: 10px 0;
    border-top: 1px solid #d8cfcf;
    border-bottom: 1px solid #d8cfcf;
    padding: 10px 0;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 12px;
`

const ImageWrapper = styled.div`
    max-width: 100%;
	
    .gatsby-image-wrapper {
		margin: 0 !important;
        width: auto !important;
        max-width: 300px;
        display: block !important;
        border-radius: 5px;

    @media(max-width: 650px) {
      width: 300px !important;
      height: 200px !important;
      margin: 10px 0 !important;
    }
}
`