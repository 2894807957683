import React, { useContext } from "react";
import L from 'leaflet';
import { isDomAvailable } from 'lib/util';
import { GlobalContext } from 'context/GlobalProvider'
import MapMarker from './MapMarker';

function MarkerList(props) {
    
    const { priceFilter, ratingFilter, typeFilter, areaFilter, markerCount, setmarkerCount } = useContext(GlobalContext);
    const recommendations = props.recommendations;

    if (isDomAvailable()) {

        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
            shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        });
    }

    const filteredRecommendations = () => {
        return recommendations
        .filter(recommendation => typeFilter === 'all' ? recommendation : (recommendation.node.type.name.toLowerCase() === typeFilter))
        .filter(recommendation => ratingFilter === 'all' ? recommendation : (recommendation.node.rating.toLowerCase() === ratingFilter))
        .filter(recommendation => priceFilter === 'all' ? recommendation : (recommendation.node.priceRange === priceFilter))
        .filter(recommendation => areaFilter === 'all' ? recommendation : (recommendation.node.area === areaFilter));
    }

    console.log(filteredRecommendations().length);


    return filteredRecommendations().map(recommendation => {
        return (
        <MapMarker key={recommendation.node.id} recommendation={recommendation} modalOpen={props.modalOpen} />

        )
    }
    );
}


export default MarkerList;