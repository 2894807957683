import React from "react";
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Img from "gatsby-image";
import Rating from 'components/Rating';
import { FaTimes, FaCheckCircle, FaGlobeEurope } from 'react-icons/fa';

import PremiumSocial from 'components/Premium/PremiumSocial';
import SimpleImageSlider from "react-simple-image-slider";


ReactModal.setAppElement('#___gatsby');


export default class Modal extends React.Component {

  render() {

  	const { isOpen, onRequestClose, selectedRecommendation } = this.props;     

    // Create new array for premium gallery
    const galleryImages = [];
    if (selectedRecommendation !== undefined && selectedRecommendation.images) {
      selectedRecommendation.images.map((image) => {
        return(
          galleryImages.push({url: image.fixed.src})
        );
      });
    }

    return (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Recommendation information"
          className="styled-modal"
          overlayClassName="modal-overlay"
        >
          <CloseBtn onClick={onRequestClose}><FaTimes /></CloseBtn>

          {selectedRecommendation !== undefined &&
          <Row>

          {selectedRecommendation.images !== null && selectedRecommendation.premium !== true &&
          	<ImageWrapper>
	          <Image fixed={selectedRecommendation.images.[0].fixed} />
	        </ImageWrapper> 
	      }

        {galleryImages && selectedRecommendation.images !== null && selectedRecommendation.premium === true &&
          	<ImageWrapper>
              <SimpleImageSlider
                width={400}
                height={300}
                images={galleryImages}
                showBullets={true}
                showNavs={true}
                navStyle={2}
              />
	        </ImageWrapper> 
	      }
	        <ContentWrapper>  
	          <Title>{selectedRecommendation.name} {selectedRecommendation.premium === true && <span className="premium"><FaCheckCircle/></span>}</Title>
	          <Industry>{selectedRecommendation.type.name}</Industry>	

		        <p><span className={`rating-${selectedRecommendation.rating}`}></span></p>
			      <Rating stars={selectedRecommendation.rating} />
			      <p><strong>Address:</strong><br/>{selectedRecommendation.address.address}</p>
			      <p><strong>Information:</strong><br/>{selectedRecommendation.description.description}</p>	
				
            {selectedRecommendation.website !== null && <SocialLinks href={selectedRecommendation.website} target="_blank"><FaGlobeEurope /></SocialLinks>}

            <PremiumSocial recommendation={selectedRecommendation} />

            <WebsiteLink href={`/recommendations/${selectedRecommendation.slug}`}>Find out more</WebsiteLink>

			    </ContentWrapper>
			</Row>	
		}

        </ReactModal>
       )
    }	
 }

const Row = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	height: 100%;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`

const ImageWrapper = styled.div`
	.gatsby-image-wrapper {
		margin: 0 !important;

    @media(max-width: 650px) {
      width: 300px !important;
      height: 200px !important;
      margin: 10px 0 !important;
    }
	}
`

const ContentWrapper = styled.div`
  @media(min-width: 768px) {
    padding-left: 30px;
  }
`

const CloseBtn = styled.button`
	position: absolute;
  top: 5px;
  right: 5px;
  appearance: none;
  background: transparent;
  border: none;
  font-size: 25px;
  padding: 0;
  transition: all 0.5s;
 	outline: none;

  &:hover {
  	color: orange;
  	cursor: pointer;
  }
`

const WebsiteLink = styled.a`
	text-decoration: none;
	background-color: #ff9900;
	color: #fff;
	padding: 10px;
  border-radius: 5px;
  display: inline-block;
  margin: 15px 2px 0 0;
  border: 1px solid #ff9900;
  transition: all 0.5s;

 	&:hover {
 		background: transparent;
 		color: #ff9900;
 		border: 1px solid #ff9900;
 		cursor: pointer;
 	}
`

const SocialLinks = styled.a`
	text-decoration: none;
	background-color: #ff9900;
	color: #fff;
	padding: 10px;
    border-radius: 5px;
    display: inline-block;
   	border: 1px solid #ff9900;
    transition: all 0.5s;
    font-size: 20px;
    line-height: 0.25em;
    vertical-align: bottom;
    margin: 15px 2px 0 2px;

   	&:hover {
   		background: transparent;
   		color: #ff9900;
   		border: 1px solid #ff9900;
   		cursor: pointer;
   	}
`

const Title = styled.h2`
	margin: 0;
  line-height: 1em;
  vertical-align: middle;

  .premium {
    font-size: 20px;
    vertical-align: middle;
  }
`

const Industry = styled.p`
	margin-top: 0;
	font-style: italic;
`

const Image = styled(Img)`
	margin: 10px auto 0 auto;
	display: flex !important;
`